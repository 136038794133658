import { lazy } from 'react';

export default class BBIFCJSViewerDescriptor{


static getDescription() {
    return {
      name: 'BB Ganttchart',
    
      component: lazy(() =>  import('./BBGanttChart.js')),
      label: 'BB Ganttchart (under development)',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?uri ?name ?start ?end ?progress ?dependencies ?graph'
        },
        {
          name: 'view_mode',
          type: 'select',
          label: 'View Mode',
          options: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month']
        },
        {
          name: 'editable',
          type: 'boolean',
          label: 'editable',
        
        }
      ]
    }
}
}