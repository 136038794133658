import { lazy } from 'react';
export default class BBMenubarDescriptor  {

static getDescription() {
    return {
      name: 'BB Menubar',
      component: lazy(() =>  import('./BBMenubar')),
      label: 'Menubar (under development)',
      type:"experimental",
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?uri ?label ?parent ?command (not yet implemented) '
           
        },

        {
          name: 'menubarconfig',
          type: 'json',
          label: 'JSON configuration for the menubar',
        },

        {
          name: 'useviewwidget',
          type: 'boolean',
          label: 'use a view widget to define multiple widgets (NOT YET IMPLEMENTED)'
        },
        {
          name: 'areas',
          type: 'json',
          //label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions'
        }
        
      ],
    }
  }
}
